import { SystemLanguage } from '@zeiss/ng-vis-common/types';
const enGBExternal = {
  omr: {
    routes: {
      title: 'OMR',
      desc: 'Tools for P0 Order Management & Routing.',
      oct: {
        desc: 'Views and manages the whole order processing lifecycle',
        title: 'Order Clearing Tool',
        initial: {
          title: 'Order search',
          description: 'Search by order information'
        },
        rxData: {
          title: 'Rx Pass search',
          description: 'Search by Rx Pass information'
        },
        buildPurchaseOrder: {
          title: 'Build Purchase Order Search',
          description: 'Build Purchase Order Search'
        },
        fileSearch: {
          title: 'File Search',
          description: 'Search and download files linked to an order'
        },
        zeebe: {
          title: 'BPMN',
          description: 'Business Process Model and Notation (BPMN)'
        },
        copo: {
          title: 'COPO Search',
          description: 'Call Off Purchase Search'
        }
      },
      rxPass: {
        desc: 'Manages Lens Pass Orders',
        title: 'Manual review - Rx Pass'
      },
      esbConfiguration: {
        desc: 'View and manage the ESB configuration',
        title: 'ESB Configuration',
        targetSystems: {
          title: 'Target Systems'
        },
        statusRouting: {
          title: 'VCA Status Routing'
        }
      },
      statusManagement: {
        desc: 'View and manage OMR statuses',
        title: 'Status Management'
      },
      orderErrors: {
        title: 'Error Handling',
        desc: 'Overview orders error'
      },
      errorMasterData: {
        title: 'Error Handling Master Data',
        desc: 'Manage error master data'
      },
      transferPrices: {
        desc: 'Overview SAP BW Transfer Price Identification',
        title: 'Transfer Prices'
      },
      outOfStock: {
        desc: 'Individualized Rx Products which are out of stock ',
        title: 'Out of stock Orders'
      }
    }
  }
};
const esESExternal = {
  omr: {
    routes: {
      title: 'OMR',
      desc: 'Herramientas para gestión y enrutamiento de pedidos P0.',
      oct: {
        desc: 'Visualiza y gestiona todo el ciclo de vida de la tramitación del pedido',
        title: 'Herramienta de compensación de pedidos',
        initial: {
          title: 'Búsqueda de pedidos',
          description: 'Búsqueda por información de pedido'
        },
        rxData: {
          title: 'Búsqueda de Rx Pass',
          description: 'Búsqueda por información de Rx Pass'
        },
        buildPurchaseOrder: {
          title: 'Búsqueda de creación de órdenes de compra',
          description: 'Búsqueda de creación de órdenes de compra'
        },
        fileSearch: {
          title: 'Búsqueda de archivos',
          description: 'Buscar y descargar archivos vinculados a una orden'
        },
        zeebe: {
          title: 'BPMN',
          description: 'Modelo y Notación de Procesos de Negocio (BPMN)"'
        },
        copo: {
          title: 'Búsqueda COPO',
          description: 'Call Off Purchase Búsqueda'
        }
      },
      rxPass: {
        desc: 'Gestiona los pedidos de Lens Pass',
        title: 'Paso Rx'
      },
      esbConfiguration: {
        desc: 'Ver y gestionar la configuración del ESB',
        title: 'Configuración del ESB',
        targetSystems: {
          title: 'Sistemas objetivo'
        },
        statusRouting: {
          title: 'Enrutamiento de estado de VCA'
        }
      },
      statusManagement: {
        desc: 'Ver y gestionar estados de OMR',
        title: 'Administración de estados'
      },
      orderErrors: {
        title: 'Manejo de errores',
        desc: 'Resumen de errores de pedidos'
      },
      errorMasterData: {
        title: 'Manejo de errores en los datos maestros',
        desc: 'Gestionar datos maestros de errores'
      },
      transferPrices: {
        desc: 'Resumen de la identificación de precios de transferencia en SAP BW',
        title: 'Precios de transferencia'
      },
      outOfStock: {
        desc: 'Productos Rx individualizados que están fuera de stock ',
        title: 'Pedidos fuera de stock'
      }
    }
  }
};
const deDEExternal = {
  omr: {
    routes: {
      title: 'OMR',
      desc: 'Order management and routing',
      oct: {
        desc: 'Transaktions- und Stammdaten',
        title: 'Order Clearing Tool',
        initial: {
          title: 'Auftragssuche',
          description: 'Suche nach Auftragsinformationen'
        },
        rxData: {
          title: 'Rx-Pass-Suche',
          description: 'Suche nach Rx-Pass-Informationen'
        },
        buildPurchaseOrder: {
          title: 'Build Purchase Order Suche',
          description: 'Suche nach Auftragsinformationen'
        },
        fileSearch: {
          title: 'Dateisuche',
          description: 'Suche nach Auftragsdatein'
        },
        zeebe: {
          title: 'BPMN',
          description: 'Geschäftsprozessmodell und -notation (BPMN)'
        },
        copo: {
          title: 'COPO Suche',
          description: 'Call Off Purchase Suche'
        }
      },
      rxPass: {
        desc: 'Verwaltet Lens-Pass-Bestellungen',
        title: 'Manual Review - Rx Pass'
      },
      esbConfiguration: {
        desc: 'Anzeigen und Verwalten von ESB Konfigurationen',
        title: 'ESB Konfigurationen',
        targetSystems: {
          title: 'Target Systems'
        },
        statusRouting: {
          title: 'VCA Status Routing'
        }
      },
      statusManagement: {
        desc: 'OMR-Status anzeigen und verwalten',
        title: 'Statusverwaltung'
      },
      orderErrors: {
        title: 'Error Handling',
        desc: 'Übersicht der fehlerhaften Aufträge'
      },
      errorMasterData: {
        title: 'Error Handling Master Data',
        desc: 'Verwaltung der Fehler-Stammdaten'
      },
      transferPrices: {
        desc: 'Übersicht der SAP BW Transferpreis-Identifikation',
        title: 'Transferpreise'
      },
      outOfStock: {
        desc: 'Aufträge, die nicht vorrätig sind ',
        title: 'Out of Stock Aufträge'
      }
    }
  }
};
const frFRExternal = {
  omr: {
    routes: {
      title: 'OMR',
      desc: 'Tools for P0 Order Management & Routing.',
      oct: {
        desc: 'Views and manages the whole order processing lifecycle',
        title: 'Order Clearing Tool',
        initial: {
          title: 'Order search',
          description: 'Search by order information'
        },
        rxData: {
          title: 'Rx Pass search',
          description: 'Search by Rx Pass information'
        },
        buildPurchaseOrder: {
          title: 'Build Purchase Order Search',
          description: 'Build Purchase Order Search'
        },
        fileSearch: {
          title: 'File Search',
          description: 'Search and download files linked to an order'
        },
        zeebe: {
          title: 'BPMN',
          description: 'Business Process Model and Notation (BPMN)'
        },
        copo: {
          title: 'COPO Search',
          description: 'Call Off Purchase Search'
        }
      },
      rxPass: {
        desc: 'Manages Lens Pass Orders',
        title: 'Manual review - Rx Pass'
      },
      esbConfiguration: {
        desc: 'View and manage the ESB configuration',
        title: 'ESB Configuration',
        targetSystems: {
          title: 'Target Systems'
        },
        statusRouting: {
          title: 'VCA Status Routing'
        }
      },
      statusManagement: {
        desc: 'View and manage OMR statuses',
        title: 'Status Management'
      },
      orderErrors: {
        title: 'Error Handling',
        desc: 'Overview orders error'
      },
      errorMasterData: {
        title: 'Error Handling Master Data',
        desc: 'Manage error master data'
      },
      transferPrices: {
        desc: 'Overview SAP BW Transfer Price Identification',
        title: 'Transfer Prices'
      },
      outOfStock: {
        desc: 'Individualized Rx Products which are out of stock ',
        title: 'Out of stock Orders'
      }
    }
  }
};
const zhCNExternal = {
  omr: {
    routes: {
      title: 'OMR',
      desc: 'Tools for P0 Order Management & Routing.',
      oct: {
        desc: 'Views and manages the whole order processing lifecycle',
        title: 'Order Clearing Tool',
        initial: {
          title: 'Order search',
          description: 'Search by order information'
        },
        rxData: {
          title: 'Rx Pass search',
          description: 'Search by Rx Pass information'
        },
        buildPurchaseOrder: {
          title: 'Build Purchase Order Search',
          description: 'Build Purchase Order Search'
        },
        fileSearch: {
          title: 'File Search',
          description: 'Search and download files linked to an order'
        },
        zeebe: {
          title: 'BPMN',
          description: 'Business Process Model and Notation (BPMN)'
        },
        copo: {
          title: 'COPO Search',
          description: 'Call Off Purchase Search'
        }
      },
      rxPass: {
        desc: 'Manages Lens Pass Orders',
        title: 'Manual review - Rx Pass'
      },
      esbConfiguration: {
        desc: 'View and manage the ESB configuration',
        title: 'ESB Configuration',
        targetSystems: {
          title: 'Target Systems'
        },
        statusRouting: {
          title: 'VCA Status Routing'
        }
      },
      statusManagement: {
        desc: 'View and manage OMR statuses',
        title: 'Status Management'
      },
      orderErrors: {
        title: 'Error Handling',
        desc: 'Overview orders error'
      },
      errorMasterData: {
        title: 'Error Handling Master Data',
        desc: 'Manage error master data'
      },
      transferPrices: {
        desc: 'Overview SAP BW Transfer Price Identification',
        title: 'Transfer Prices'
      },
      outOfStock: {
        desc: 'Individualized Rx Products which are out of stock ',
        title: 'Out of stock Orders'
      }
    }
  }
};

// Translations which can be used in VP outside library. Please do not export everything, this is exception, not a rule!
const OMR_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: {
    ...enGBExternal
  },
  [SystemLanguage['es-ES']]: {
    ...esESExternal
  },
  [SystemLanguage['de-DE']]: {
    ...deDEExternal
  },
  [SystemLanguage['fr-FR']]: {
    ...frFRExternal
  },
  [SystemLanguage['zh-CN']]: {
    ...zhCNExternal
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { OMR_TRANSLATIONS };
