import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
const OMR_PATHS = {
  _: 'omr',
  oct: {
    _: 'clearing',
    initial: 'order',
    rxData: 'rx-pass',
    buildPurchaseOrder: 'build-purchase-order',
    fileSearch: 'file-search',
    zeeBe: 'zeebe',
    copo: 'copo'
  },
  rxPass: 'rxpass',
  esbConfiguration: {
    _: 'esb-configuration',
    targetSystems: {
      _: 'target-systems',
      edit: 'edit'
    },
    statusRouting: {
      _: 'vca-status-routing'
    }
  },
  errorHandling: {
    _: 'error-handling'
  },
  errorHandlingMasterData: {
    _: 'error-handling-master-data'
  },
  statusManagement: 'status-management',
  transferPrices: 'transfer-prices',
  outOfStock: 'out-of-stock'
};
const OMR_ROUTES = {
  path: OMR_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'external.omr.routes.title',
    description: 'external.omr.routes.desc',
    icon: 'zui-icon-flip-flip-stack',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_omr,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: OMR_PATHS.oct._,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.omr.routes.oct.title',
        description: 'external.omr.routes.oct.desc',
        icon: 'zui-icon-edf-edf-active',
        showInMenu: true,
        hierarchy: 20,
        cache: true,
        absoluteUrl: `${OMR_PATHS._}/${OMR_PATHS.oct._}`,
        rule: vpRule({
          rule: RULE.access_omr,
          required: ['clearing']
        })
      }
    }, {
      path: OMR_PATHS.rxPass,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.omr.routes.rxPass.title',
        description: 'external.omr.routes.rxPass.desc',
        icon: 'zui-icon-setup-qualification-measurement',
        showInMenu: true,
        hierarchy: 19,
        cache: true,
        rule: vpRule({
          rule: RULE.access_omr,
          required: ['rxpass']
        })
      }
    }, {
      path: OMR_PATHS.errorHandling._,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.omr.routes.orderErrors.title',
        description: 'external.omr.routes.orderErrors.desc',
        icon: 'zui-icon-hard-drive-add-on-hard-drive-add-on-warning',
        showInMenu: true,
        hierarchy: 18,
        cache: true,
        rule: vpRule({
          rule: RULE.access_omr,
          required: ['error-handling']
        })
      }
    }, {
      path: OMR_PATHS.errorHandlingMasterData._,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.omr.routes.errorMasterData.title',
        description: 'external.omr.routes.errorMasterData.desc',
        icon: 'zui-icon-hard-drive-add-on-hard-drive-add-on-warning',
        showInMenu: true,
        hierarchy: 17,
        cache: true,
        rule: vpRule({
          rule: RULE.access_omr,
          required: ['error-handling-master-data']
        })
      }
    }, {
      path: OMR_PATHS.transferPrices,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.omr.routes.transferPrices.title',
        description: 'external.omr.routes.transferPrices.desc',
        icon: 'zui-icon-file-file-file-transfer',
        showInMenu: true,
        hierarchy: 16,
        cache: true,
        rule: vpRule({
          rule: RULE.access_omr,
          required: ['transfer-prices']
        })
      }
    }, {
      path: `${OMR_PATHS.esbConfiguration._}`,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.omr.routes.esbConfiguration.title',
        description: 'external.omr.routes.esbConfiguration.desc',
        icon: 'zui-icon-system-parameters',
        showInMenu: true,
        hierarchy: 15,
        cache: true,
        rule: vpRule({
          rule: RULE.access_omr,
          required: ['esb-configuration']
        })
      }
    }, {
      path: OMR_PATHS.statusManagement,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.omr.routes.statusManagement.title',
        description: 'external.omr.routes.statusManagement.desc',
        icon: 'zui-icon-textual-view',
        showInMenu: true,
        hierarchy: 14,
        cache: true,
        rule: vpRule({
          rule: RULE.access_omr,
          required: ['status-management']
        })
      }
    }, {
      path: OMR_PATHS.outOfStock,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.omr.routes.outOfStock.title',
        description: 'external.omr.routes.outOfStock.desc',
        icon: 'zui-icon-processing-list',
        showInMenu: true,
        hierarchy: 14,
        cache: true,
        rule: vpRule({
          rule: RULE.access_omr,
          required: ['out-of-stock']
        })
      }
    }]
  }
};

/*
 * Public API Surface of ng-vis-omr/external
 */

/**
 * Generated bundle index. Do not edit.
 */

export { OMR_PATHS, OMR_ROUTES };
